import { ChangeHistoryData, Footer, SearchBar, SearchEmptyState } from '@/components';
import { useChangeHistoryStore } from './store';
import { useEffect } from 'react';
import { Loading } from './shared';
import { FloraButton, Flex } from '@grupoboticario/flora-react';
import { CrossCircleIcon, MagnifyingGlassIcon } from '@grupoboticario/flora-react-icons';

const App = () => {
  const fetchData = useChangeHistoryStore((state) => state.fetchByAll);
  const isLoading = useChangeHistoryStore((state) => state.isLoading);
  const hasError = useChangeHistoryStore((state) => state.hasError);
  const data = useChangeHistoryStore((state) => state.data);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Flex css={{ height: '100vh' }} justify='center'>
        <Loading
          size='lg'
          message='Carregando informações'
          subtitle='Por favor, aguarde um instante'
        />
      </Flex>
    );
  }

  if (hasError) {
    return (
      <>
        <SearchBar />
        <SearchEmptyState
          title={'Não foi possível carregar os dados'}
          subtitle={'Por favor, tente novamente'}
          icon={<CrossCircleIcon size={48} />}
          button={
            <FloraButton size='medium' onClick={fetchData}>
              Tentar novamente
            </FloraButton>
          }
        />
      </>
    );
  }

  if (!data.length) {
    return (
      <>
        <SearchBar />
        <SearchEmptyState
          title={'Nenhum resultado encontrado'}
          subtitle={'Por favor, tente buscar alguma outra palavra'}
          icon={<MagnifyingGlassIcon size={48} />}
        />
      </>
    );
  }

  return (
    <>
      <SearchBar />
      <ChangeHistoryData />
      <Footer />
    </>
  );
};

export { App };
