import { Flex, FloraTypography } from '@grupoboticario/flora-react';

interface ResultNotFoundProps {
  title: string;
  subtitle: string;
  icon: React.ReactNode;
  button?: React.ReactNode;
}

export const SearchEmptyState = ({ title, subtitle, icon, button }: ResultNotFoundProps) => {
  return (
    <Flex gap='$4' css={{ marginTop: '$12' }} direction='column' align='center' justify='center'>
      {icon}
      <FloraTypography fontSize='subtitle'>{title}</FloraTypography>
      <FloraTypography fontSize='bodyLargeStandard'>{subtitle}</FloraTypography>
      {button}
    </Flex>
  );
};
