import { FloraButton } from '@grupoboticario/flora-react';
import { ArrowRightIcon } from '@grupoboticario/flora-react-icons';

export const SearchBarIcon = () => (
  <FloraButton
    data-testid='search-bar-icon'
    type='submit'
    hierarchy='tertiary'
    has='iconOnly'
    icon={<ArrowRightIcon />}
  />
);
